<template>
  <v-app>
    <v-app-bar
      app
      color="#5E539A"
      dark
    >
      <v-row justify="center">
        <v-col cols="9" md="7" class="text-h7 text-md-h6 font-weight-medium">
          <router-link to="/" class="text-decoration-none white--text">
            住宅トップランナー制度 <span class="text-no-wrap">ファイルチェックシステム</span>
          </router-link>
        </v-col>
        <v-col cols="3" class="d-flex justify-end align-center">
          <a href="/document/toprunner_filecheck_manual.pdf" target="_blank" class="pr-4 caption text-md-subtitle-2 el_btn__dl">使い方
            <v-icon
              small
              color="white"
            >
              mdi-file-pdf-box
            </v-icon>
          </a>
          <router-link to="/faq" target="_blank" class="caption text-md-subtitle-2 el_btn__dl">よくある質問
            <v-icon
              small
              color="white"
            >
              mdi-open-in-new
            </v-icon>
          </router-link>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main fluid class="bg_pale ">
      <v-container fluid class="full-height">
        <v-row fluid justify="center"  style="min-height:calc(100vh - 65px);">
          <v-col cols="12" md="10" xl="10" full-height class="pt-0 px-0 pb-16 white">
            <v-container class="pt-0 mb-0 d-flex fill-height align-start bl_main_contentsWrapper">
              <router-view/>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  mounted() {
    // リロードする前のイベントを作成する
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  beforeDestroy() {
    // リロードする前のイベントを削除する
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },

  methods: {
    // リロード「F5」する際、確認ダイアログボックスを表示する
    beforeWindowUnload(e) {
      // storeのデータ修正していない場合、確認ダイアログボックスを表示しない
      if (this.$route.path == '/' || this.$route.path == '/terms') return

      // storeのデータ修正する中の場合、確認ダイアログボックスを表示する
      e.preventDefault()
      e.returnValue = "Data Modifying..."
    }
  }
};
</script>
<style>
#app {
  font-family: 游ゴシック;
}
.v-application a.el_btn__dl{
  color: white;
  text-decoration: none;
}
@media (min-width: 960px){
  .bl_main_contentsWrapper{
    justify-content: center;
    max-width: clamp(1185px, 100%, 90vw) !important;
  }
  .bl_main_contentsWrapper .bl_errorPage,
  .bl_main_contentsWrapper .bl_checkedListPage {
    max-width: 100% !important;
  }
}

</style>
