import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from '@/store/index.js'
import VueGtag from "vue-gtag";
import './sass/variable.scss'

Vue.config.productionTip = false

const agent = window.navigator.userAgent.toLowerCase()
if (agent.indexOf("trident") != -1) {
  alert("Internet Explorer には対応していません。")
  window.location.href = 'https://www.microsoft.com/ja-jp/edge'
}

// Google Analyticsの設定
const host = window.location.hostname
if (host != "localhost") {
  Vue.use(VueGtag, {
    config: { id: "G-MMKC6V893W" }
  }, router)
}

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
