import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

// ディフォルトstate
const defaultState = () => {
  return {
    // 使用許諾条件
    isTermAccepted: false,
    // 住宅の建て⽅
    householdTypes: [
      '建売戸建住宅',
      '注文戸建住宅',
      '賃貸アパート・分譲マンション'
    ],
    // 選択された住宅の建て⽅
    selectedHouseholdType: '',
    // 報告方法
    reportTypes: [
      '報告方法i',
      '報告方法ii'
    ],
    // 選択された報告方法
    selectedReportType: '',
    // アップロードされたファイル名
    uploadedFileNames: [],
    // ファイルチェックの状態
    isFileChecked: false,
    // ファイルチェックの結果
    checkedResult: null,
    // ファイル表の選択された行のインデックス
    selectedIndex: -1,
    // ファイル表の選択されたファイルのURL
    selectedPdfSrc: null,
    // 差し替える処理のフラグ値
    isReplace: false,
    // メッセージ
    message: '',
    // メッセージのタイプ
    messageType : '',
  }
}

export default new Vuex.Store({
  state: defaultState,

  getters: {
    data: state => {
      return state
    },
    // 使用許諾条件
    isTermAccepted: state => {
      return state.isTermAccepted
    },
    // エラーリスト
    message: state => {
      return state.message
    },
    // メッセージのタイプ
    messageType: state => {
      return state.messageType
    },
    // エラーのフラグ値
    isError: state => {
      return state.messageType == 'error'
    },
    // 住宅の建て⽅
    householdTypes: state => {
      return state.householdTypes
    },
    // 選択された住宅の建て⽅
    selectedHouseholdType: state => {
      return state.selectedHouseholdType
    },
    // 報告方法
    reportTypes: state => {
      return state.reportTypes
    },
    // 選択された報告方法
    selectedReportType: state => {
      return state.selectedReportType
    },
    // アップロード済みファイル数
    fileCnt: state => {
      return state.uploadedFileNames.length
    },
    // アップロードされたファイル名
    uploadedFileNames: state => {
      return state.uploadedFileNames
    },
    // ファイルチェックの状態
    isFileChecked: state => {
      return state.isFileChecked
    },
    // ファイルチェックの結果
    checkedResult: state => {
      return state.checkedResult
    },
    // チェック済みファイルの総数
    totalCheckedCnt : state => {
      return state.checkedResult.length
    },
    // 住⼾数
    householdCnt: state => {
      let householdCnt = 0
      const correctList = correctResults(state)
      correctList.forEach((el) => householdCnt += el.household_num)
      
      return householdCnt
    },
    // 正しいファイル結果リスト
    correctResults: state => {
      return correctResults(state)
    },
    // 正しい結果の数
    correctCnt: state => {
      const correctList = correctResults(state)
      return correctList.length
    },
    // 平均BEI
    averageBEI: (_state, getters) => {
      let correctResults = getters.correctResults
      let totalETGJWO = 0
      let totalESTGJWO = 0

      // 設計一次エネルギー消費量（その他の設計一次エネルギー消費量を除く）の合計
      correctResults.forEach((el) => totalETGJWO += el.eTGJWO)
      // 基準一次エネルギー消費量（その他の設計一次エネルギー消費量を除く）の合計
      correctResults.forEach((el) => totalESTGJWO += el.eSTGJWO)

      // 平均BEI
      let averageBEI = totalETGJWO / totalESTGJWO
      // 小数点以下二位未満の端数があるときは切り上げ
      return Math.ceil(averageBEI * 100) / 100
    },
    // エラー結果
    errorResults : state => {
      return errorResults(state)
    },
    // エラー結果の数
    errorCnt: state => {
      const errorList = errorResults(state)
      return errorList.length
    },
    // ファイル表の選択された行のインデックス
    selectedIndex: state => {
      return state.selectedIndex
    },
    // ファイル表の選択されたファイルのURL
    selectedPdfSrc: state => {
      return state.selectedPdfSrc
    },
  },

  mutations: {
    // stateの更新
    update(state, values) {
      Object.keys(values).forEach(key => {
        Vue.set(state, key, values[key])
      })
    },
    // stateのアペンド
    append(state, values){
      Object.keys(values).forEach(key => {
        Vue.set(state, key, state[key].concat(values[key]))
      })
    },
    // stateのリセット
    reset(state, value){
      state = Object.assign(state, value)
    },
    // アップロードされたファイル名を更新する
    updateUploadedFileNames(state, fileNames){
      const arr = state.uploadedFileNames.concat(fileNames)
      state.uploadedFileNames = arr
    },
    // アップロードされたファイル名を削除する
    deleteUploadedFileName(state, fileName){
      const toDeleteIndex = state.uploadedFileNames.findIndex((name) => name == fileName)
      state.uploadedFileNames.splice(toDeleteIndex, 1)
    },
    // ファイルチェック結果を更新する
    updateCheckedResult(state, value){
      const array = state.checkedResult.concat(value)
      state.checkedResult = array
    },
    // チェック結果からエラーファイルを削除する
    deleteErrorResults(state, index) {
      const errorList = errorResults(state)
      const toDeleteIndex = state.checkedResult.findIndex((result) => result.hashedName == errorList[index].hashedName)
      state.checkedResult.splice(toDeleteIndex, 1)
    }
  },

  actions: {
    // stateの更新
    update({commit}, values) {
      commit('update', values)
    },
    // stateのアペンドを呼び出す
    append({commit}, values){
      commit('append', values)
    },
    //isTermAcceptedを更新する
    updateIsTermAccepted({commit}, isTermAccepted) {
      commit('update', {isTermAccepted:isTermAccepted})
    },
    // アップロードされたファイル名を更新する
    updateUploadedFileNames({commit}, fileNames){
      commit('updateUploadedFileNames', fileNames)
    },
    // アップロードされたファイル名を削除する
    deleteUploadedFileName({commit}, fileName){
      commit('deleteUploadedFileName', fileName)
    },
    // メッセージの更新
    updateMessage({commit}, {message, type}) {
      commit('update', {message:message, messageType:type})
    },
    // メッセージのクリア
    clearMessage({commit}){
      commit('update', {message:'', messageType:''})
    },
    // ファイルチェック結果を更新する
    updateCheckedResult({commit, state}, {checkedResult, deleteIndex}) {
      // 差し替える場合
      if(typeof deleteIndex != 'undefined') {
        // チェック結果から差し替えられたエラーファイルを削除する
        commit('deleteErrorResults', deleteIndex)
      }

      // チェック結果がない場合、更新
      if(state.checkedResult == null) {
        commit('update', {checkedResult:checkedResult})
      } else {
        // チェック結果がある場合、アペンド
        commit('updateCheckedResult', checkedResult)
      }
    },
    // ファイル表の選択されたファイルのURLをクリアする
    clearSelectedPdfSrc({commit}) {
      commit('update', {selectedPdfSrc: null})
    },
    //----------------------------------------------------
    //---------------API処理------------------------------
    //----------------------------------------------------
    // stateのリセット
    async reset({dispatch, commit}){
      // アップロード済みファイルがある場合、バクエンドにファイル情報を削除する
      await dispatch('deleteFileInfo')
      commit('reset', defaultState())
    },
    // ファイルチェックする
    async checkFile({dispatch, getters, state}, isReplace) {
      return await axios.post('api/check', {
        householdType: state.selectedHouseholdType,
        reportType: state.selectedReportType,
        toReplaceFileName: isReplace ? getters.errorResults[state.selectedIndex].hashedName : null
      })
      .then(response => {
          return response.data
      })
      .catch(() => {
        dispatch('updateMessage', {
          message: "ファイルのチェックができませんでした。アップロードしたファイルを確認してください。",
          type: 'error'
        })
      })
    },
    // Jobの実行状態の確認
    async checkJobStatus({dispatch}, batch_id) {
      return await axios.get(`/api/${batch_id}/status`)
        .then(response => {
          return response.data
        }).catch(error => {
          const status = error.response.data['job_status']
          let message = ''

          if ( status == 'failed') {
            message = "ファイルのチェックができませんでした。アップロードしたファイルを確認してください。"
          } else {
            message = "タイムアウトしました。しばらく間を空けてから再度お試しください。"
          }

          dispatch('updateMessage', {
            message: message,
            type: 'error'
          })
          return status
        })
    },
    // ファイルチェック結果を取得する
    async getCheckedResult({dispatch}, batch_id) {
      return await axios.get(`/api/${batch_id}/result`)
        .then(response => {
          return response.data.checkedResult
        }).catch(() => {
          dispatch('updateMessage', {
            message: "ファイルのチェックができませんでした。アップロードしたファイルを確認してください。",
            type: 'error'
          })
        })
    },
    // セッションから全てのデータを削除する
    async deleteFileInfo() {
      await axios.post('api/forgetSession')
    },
    // ファイルの取得する処理
    async getPdf({dispatch, commit, getters}, index) {
      const fileName = getters.errorResults[index].hashedName

      return await axios.get('/api/getPdf',
      { 
        params: {fileName: fileName}, 
        responseType: "blob"
      }).then(response => {
        const blob = new Blob([response.data])
        const pdfSrc = URL.createObjectURL(blob)

        commit('update', {
          selectedPdfSrc : pdfSrc
        })
      }).catch(() => {
        dispatch('updateMessage', {
          message: "ファイルの取得する処理に失敗しました。",
          type: 'error'
        })
      })
    },
    // ジップファイルのダウンロード処理
    async downloadZip({dispatch}) {
      return await axios.get('/api/download',
      { 
        responseType: "blob"
      }).then(response => {
        return response.data
      }).catch(() => {
        dispatch('updateMessage', {
          message: "ZIPファイルのダウンロード処理に失敗しました。",
          type: 'error'
        })
      })
    },
    // 住宅版計算結果pdfファイルを削除する
    async deleteFile({dispatch, commit, getters, state}) {
      const deleteFile = getters.errorResults[state.selectedIndex]
      const hashedName = deleteFile.hashedName
      const originalName = deleteFile.originalName

      return await axios.post('/api/delete', {
        fileName: hashedName
      }).then(response => {
        if (response.status == 200) {
          // エラーファイルを削除する
          commit('deleteErrorResults', state.selectedIndex)
          // アップロードされたファイル名から削除する
          commit('deleteUploadedFileName', originalName)
          commit('update', {selectedIndex : -1})
          dispatch('updateMessage', {
            message: originalName + 'が削除されました。',
            type: 'success'
          })
        }
       
      }).catch(() => {
        dispatch('updateMessage', {
          message: "ファイルの削除処理に失敗しました。",
          type: 'error'
        })
      })
    },
    // アップロード済みファイルを削除する
    async deleteUploadedFiles(context, fileNames) {
      await axios.post('api/deleteUploadedFiles', {
        fileNames: fileNames
      })
    },
  }
})

// 正しいファイル結果リスト
function correctResults (state) {
  return state.checkedResult.filter((result) => result.error_msg == '')
}

// エラーファイル結果リスト
function errorResults (state) {
  return state.checkedResult.filter((result) => result.error_msg != '')
}
