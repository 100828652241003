import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {  path:'', name:'home', component: () => import('@/views/Home.vue')},
  // 使用許諾条件画面
  {  path:'/terms', name:'terms', component: () => import('@/views/Terms.vue')},
  // 住宅の建て⽅の選択画面
  {  path:'/household-type', name:'householdType', component: () => import('@/views/HouseholdTypes.vue')},
  // 報告方法の選択画面
  {  path:'/report-type', name:'reportType',  component: () => import('@/views/ReportTypes.vue')},
  // ファイルアップロード画面
  {  path:'/file-upload', name:'fileUpload', component: () => import('@/views/HouseholdFileUpload.vue')},
  // ファイルチェック画面
  {  path:'/file-check', name:'fileCheck', component: () => import('@/views/HouseholdFileCheck.vue')},
  // エラーファイルの一覧画面
  {  path:'/error-result', name:'errorResult', component: () => import('@/views/ErrorResult.vue')},
  // ファイルの詳細画面
  {  path:'/file-detail', name:'fileDetail', component: () => import('@/views/FileDetail.vue')},
  // チェック通過済みファイルの一覧
  {  path:'/file-list', name:'fileList', component: () => import('@/views/CheckedFileList.vue')},
  // よくある質問
  {  path:'/faq', name:'faq', component: () => import('@/views/FrequentlyAskedQuestions.vue')},
  // 新機能のお知らせランディングページ
  {  path:'/news', name:'news', component: () => import('@/views/NewContents.vue')},
]

const router = new VueRouter({
  routes,
  
  //スクロール位置を指定
  scrollBehavior(to, from, savedPosition){
    if(savedPosition){
      //savedPositionがあるとvueデフォルトのスクロール挙動になるようです（スクロール位置がページ遷移後も保存される）
      return savedPosition
    } else if (to.hash) {
      const position = {}
      position.selector = to.hash
      //ページ内リンクがヘッダーにかかって見えなくならないようオフセット
      position.offset = { y: 120 }
      return position
    } else {
      //savedPositionがなく、かつ、router-linkで＃を使用していない場合はスクロール位置がtopになります。
      return { x:0, y:0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    // 入力内容のリセット
    store.dispatch('reset')
    next()
  } else {
    // URLの制限をチェックする
    if (isRouteRulePassed(to, from.path)) {
      next()
    } else {
      next({ path: '/' })
    }
  }
})

// URLの制限
const allowedBfrRoutePath = {
  terms: ['/', '/household-type'],
  householdType: ['/terms', '/report-type'],
  reportType: ['/household-type', '/file-upload'],
  fileUpload: ['/report-type', '/file-check'],
  fileCheck: ['/file-upload', '/error-result', '/file-list'],
  errorResult: ['/file-check', '/file-detail'],
  fileDetail: ['/error-result'],
  fileList: ['/file-check'],
  faq: ['/', '/terms', '/household-type', '/report-type', '/file-upload', '/report-type', '/file-check', '/error-result', '/file-list', '/file-detail'],
  news: ['/'],
}

// URLの制限をチェックする
function isRouteRulePassed (to, frmPath) {
  // 不正なURLの場合
  if (to.matched.length == 0) return false

  const isTermAccepted = store.getters.isTermAccepted
  const isLicensePass = !isTermAccepted && to.path !== '/' && to.path !=='/terms' && to.path !=='/faq' && to.path !=='/news' ? false : true

  return isLicensePass && allowedBfrRoutePath[to.name].includes(frmPath)
}

export default router
