import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#5E539A', //紫 
            secondary: "#AEA8CC", //薄い紫
            bg_pale: '#edebf4', //背景用薄紫
            cl_error: '#e13d3d',//エラー時の赤
            cl_error_lignt: '#f09e9e',//エラー時の赤 - 明るめ
            success: '#5e976a', //地味目の緑
            cl_success_light: "#93c5b3",//地味目の緑 - 明るめ
            text_gray: '#333333' //真っ黒ではない黒
            },
        },
    },
});